import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Popover, Transition, Fragment } from "@headlessui/react"
import Layout from "../components/layout"
import Footer from "../components/footer"

const IndexPage = () => {
  const data = useStaticQuery(query)

  const [footer, showFooter] = React.useState(false)
  const [showText, setShowText] = React.useState({
    work: false,
    question: false,
    something: false,
  })

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div class="shadow-lg rounded-b-3xl lg:py-12 px-6 lg:px-16">
        <div class="flex flex-col gap-12">
          <div class="lg:py-12 py-8">
            <p class="text-small lg:text-demilarge font-bold text-primary">
              Get in touch.
            </p>
          </div>
          <div class="lg:py-16 pb-16 lg:flex lg:justify-end">
            <div class="flex flex-col text-navbar gap-6 lg:w-1/2 text-xl">
              <Popover>
                <a
                  className="hover:font-bold pointer"
                  onClick={() => {
                    setShowText({ ...showText, work: !showText.work })
                    navigator.clipboard.writeText("info@articulate.ventures")
                  }}
                >
                  Work with us
                </a>
                <div className="text-sm">
                  {showText.work ? "Email copied to clipboard" : ""}
                </div>
              </Popover>
              <hr class="border-primary" />
              <Popover>
                <a
                  className="hover:font-bold pointer"
                  onClick={() => {
                    setShowText({ ...showText, question: !showText.question })
                    navigator.clipboard.writeText("info@articulate.ventures")
                  }}
                >
                  Ask a question
                </a>
                {showText.question ? (
                  <div className="text-sm">Email copied to clipboard</div>
                ) : (
                  ""
                )}
              </Popover>
              <hr class="border-primary" />
              <span
                className="hover:font-bold pointer"
                onClick={() => {
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                  })
                  showFooter(!footer)
                }}
              >
                Subscribe to our newsletter.
              </span>
              <hr class="border-primary" />
              <Popover>
                <a
                  className="hover:font-bold pointer"
                  onClick={() => {
                    setShowText({ ...showText, something: !showText.something })
                    navigator.clipboard.writeText("info@articulate.ventures")
                  }}
                >
                  Something Else
                </a>
                {showText.something ? (
                  <div className="text-sm">Email copied to clipboard</div>
                ) : (
                  ""
                )}
              </Popover>
              <hr class="border-primary" />
            </div>
          </div>
        </div>
        <Footer footer={footer} />
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiArticles {
      edges {
        node {
          strapiId
          slug
          title
          categories {
            title
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 660)
              }
            }
          }
          author {
            name
          }
        }
      }
    }
  }
`

export default IndexPage
